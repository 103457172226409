<script>
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'

export default {
  name: "ModalImportMikrotik",
  data() {
    return {
      analizeResult: {
          ispPlans: 0,
          ispSubscriptions: 0,
        },
      form: {
        file: undefined,
        ispPlans: false,
        ispSubscriptions: false,
      },
    }
  },
  methods: {
    open() {
      this.resetAll();
      document.getElementById('btn-modal-import-from-mikrotik-abre').click();
    },

    resetAll() {
      document.getElementById('file-upload-mikrotik-export').value= null;
      this.analizeResult = undefined;
      this.form.ispPlans = false;
      this.form.ispSubscriptions = false;
    },

    close() {
      document.getElementById('btn-modal-import-from-mikrotik-fecha').click();
    },

    btnUploadHandle(){
      this.resetAll()
      document.getElementById('file-upload-mikrotik-export').click();
    },

    onFileChange(event) {
      if (!event.target.files[0]) {
        return;
      }

      let data = new FormData();
      data.append('file', event.target.files[0]);

      loading(true);
      api.post(`/importer/mikrotik/analize`, data).then(res => {
        loading(false);
        this.analizeResult = res.data;
        this.form.ispPlans = (!!this.analizeResult.ispPlans);
        this.form.ispSubscriptions = (!!this.analizeResult.ispSubscriptions);
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    btnImportHandle() {
      let data = new FormData();
      data.append('file', document.getElementById('file-upload-mikrotik-export').files[0]);
      data.append('ispPlans', this.form.ispPlans);
      data.append('ispSubscriptions', this.form.ispSubscriptions);

      loading(true);
      api.post(`/importer/mikrotik`, data).then(() => {
        loading(false);
        this.resetAll();
        dialogo.info("A importação foi executada com sucesso.");
        this.close();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
  },
};
</script>

<template>
  <div>
    <!-- modal-import-from-mikrotik -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-import-from-mikrotik" id="btn-modal-import-from-mikrotik-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-import-from-mikrotik" tabindex="-1" role="dialog" aria-labelledby="modalOnuInfo" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'file-import']" fixed-width/>
              Importar do Mikrotik
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <p class="fw-bold mb-0">Instruções:</p>
                - Acesse o seu concentrador PPPoE via winbox
                <br>- Clique no menu <strong>New Terminal</strong>
                <br>- Execute este comando no terminal: <strong>/export file=sgm-export</strong>
                <br>- Clique no menu <strong>Files</strong>
                <br>- Selecione o arquivo <strong>sgm-export.rsc</strong> e clique em <strong>Copy</strong> (botão com o ícone de 2 folhas de papel)
                <br>- Cole em alguma pasta no seu computador
                <br>- Clique no botão <strong>Enviar arquivo</strong> abaixo e localize no seu computador o arquivo <strong>sgm-export.rsc</strong>
              </div>
              <div class="col-12 pt-3">
                <input type="file" @change="onFileChange" id="file-upload-mikrotik-export" accept=".rsc" class="d-none">
                <button v-on:click="btnUploadHandle()" type="button" class="btn btn-secondary">
                  <font-awesome-icon :icon="['fas', 'upload']" fixed-width/> Enviar arquivo
                </button>
              </div>
              <div v-if="(analizeResult)" class="row mt-3">
                <div class="col-12">
                  <p class="fw-bold mb-0">Importar:</p>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" v-model="form.ispPlans" type="checkbox" id="mikrotik-import-ispPlans">
                    <label class="form-check-label cursor-pointer" for="mikrotik-import-ispPlans">
                      Planos ({{ analizeResult.ispPlans }})
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" v-model="form.ispSubscriptions" type="checkbox" id="mikrotik-import-ispSubscriptions">
                    <label class="form-check-label cursor-pointer" for="mikrotik-import-ispSubscriptions">
                      Assinaturas ({{ analizeResult.ispSubscriptions }})
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <button v-on:click="btnImportHandle()" type="button" class="btn btn-success">
                    <font-awesome-icon :icon="['fas', 'check']" fixed-width/> Importar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-import-from-mikrotik-fecha">Fechar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-import-from-mikrotik -->
  </div>
</template>
