<template>
  <div class="contas">
    <h1 class="titulo-card fs-4">Meu negócio</h1>
    <ModalImportMikrotik ref="modalImportMikrotik"/>
    <ModalImportMkauth ref="modalImportMkauth"/>
    <div class="form-group card-principal">
      <div class="row">
        <div class="col">
        </div>
        <div class="col-auto">
          <div class="dropdown open">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="triggerId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <font-awesome-icon @click="handleEditCompany()" :icon="['fas', 'file-import']" fixed-width class="me-1"/>
              Importar de outro sistema
            </button>
            <div class="dropdown-menu" aria-labelledby="triggerId">
              <button class="dropdown-item" @click="$refs.modalImportMikrotik.open()" href="#">Mikrotik</button>
              <button class="dropdown-item" @click="$refs.modalImportMkauth.open()" href="#">MK-Auth</button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm table-striped table-hover mb-1">
          <thead>
            <tr>
              <th width="120">Campo</th>
              <th>
                Valor
              </th>
            </tr>
          </thead>
          <tbody v-if="company">
            <tr>
              <td scope="row">CNPJ</td>
              <td>{{company.legalId || '-'}}</td>
            </tr>
            <tr>
              <td scope="row">Nome fantasia</td>
              <td>{{company.name || '-'}}</td>
            </tr>
            <tr>
              <td scope="row">Razão social</td>
              <td>{{company.legalName || '-'}}</td>
            </tr>
            <tr>
              <td scope="row">Telefone</td>
              <td>{{company.phone || '-'}}</td>
            </tr>
            <tr>
              <td scope="row">CEP</td>
              <td>{{company.zip || '-'}}</td>
            </tr>
            <tr>
              <td scope="row">Endereço (linha 1)</td>
              <td>{{company.addressLine1 || '-'}}</td>
            </tr>
            <tr>
              <td scope="row">Endereço (linha 2)</td>
              <td>{{company.addressLine2 || '-'}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button @click="handleEditCompany()" class="btn btn-outline-secondary" type="button">
        <font-awesome-icon :icon="['fas', 'pen']" fixed-width class="me-1"/>
        Editar
      </button>
    </div>
  </div>
</template>

<script>
import api from '../modules/api'
import dialogo from '../modules/dialogo'
import loading from '../modules/loading'
import ModalImportMikrotik from '@/components/ModalImportMikrotik.vue'
import ModalImportMkauth from '@/components/ModalImportMkauth.vue'

export default {
  name: 'ConfigCompany',
  components: {
    ModalImportMikrotik,
    ModalImportMkauth,
  },
  data() {
    return {
      company: undefined,
    }
  },
  methods: {
    loadCompany() {
      loading(true);

      api.get(`/config/company`).then(res => {
        loading(false);
        this.company = res.data;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    handleEditCompany() {
      this.$router.push('/configuracoes/meu-negocio/company/edit');
    },
  },
  created() {
    this.loadCompany();
  }
}
</script>
