<script>
import api from '@/modules/api'
import loading from '@/modules/loading'
import dialogo from '@/modules/dialogo'

export default {
  name: "ModalImportMkauth",
  data() {
    return {
      analizeResult: {
        banks: 0,
        ispPlans: 0,
        customers: 0,
        ispSubscriptions: 0,
        boletos: 0,
        connectionHistory: 0,
        tickets: 0,
      },
      analizeForm: {
        host: '',
        port: 0,
        username: '',
        password: '',
      },
      importForm: {
        banks: false,
        ispPlans: false,
        customers: false,
        ispSubscriptions: false,
        boletos: false,
        connectionHistory: false,
        tickets: false,
      },
    }
  },
  methods: {
    open() {
      this.resetAll();
      document.getElementById('btn-modal-import-from-mkauth-abre').click();
    },

    resetAll() {
      this.analizeResult = undefined;

      this.analizeForm.host = '';
      this.analizeForm.port = undefined;
      this.analizeForm.username = '';
      this.analizeForm.password = '';

      this.importForm.banks = false;
      this.importForm.ispPlans = false;
      this.importForm.customers = false;
      this.importForm.ispSubscriptions = false;
      this.importForm.boletos = false;
      this.importForm.connectionHistory = false;
      this.importForm.tickets = false;
    },

    canAnalize() {
      switch (true) {
        case (!this.analizeForm.host):
        case (!this.analizeForm.port):
        case (!this.analizeForm.username):
        case (!this.analizeForm.password):
          return false;

        default:
          return true;
      }
    },

    close() {
      document.getElementById('btn-modal-import-from-mkauth-fecha').click();
    },

    btnAnalizeHandle() {
      loading(true);
      api.post(`/importer/mkauth/analize`, this.analizeForm).then(res => {
        loading(false);
        this.analizeResult = res.data;
        this.importForm = {
          banks: (!!this.analizeResult.banks),
          ispPlans: (!!this.analizeResult.ispPlans),
          customers: (!!this.analizeResult.customers),
          ispSubscriptions: (!!this.analizeResult.ispSubscriptions),
          boletos: (!!this.analizeResult.boletos),
          connectionHistory: (!!this.analizeResult.connectionHistory),
          tickets: (!!this.analizeResult.tickets),
        };
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    btnImportHandle() {
      const data = {
        connection: this.analizeForm,
        importConfig: this.importForm,
      }

      loading(true);
      api.post(`/importer/mkauth`, data).then(() => {
        loading(false);
        this.resetAll();
        dialogo.info("A importação foi executada com sucesso.");
        this.close();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },
  },
};
</script>

<template>
  <div>
    <!-- modal-import-from-mkauth -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-import-from-mkauth" id="btn-modal-import-from-mkauth-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-import-from-mkauth" tabindex="-1" role="dialog" aria-labelledby="modalOnuInfo" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'file-import']" fixed-width/>
              Importar do MK-Auth
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <p class="fw-bold mb-0">Instruções:</p>
                - Acesse o seu concentrador PPPoE via winbox
                <br>- Seu MK-Auth deve permitir conexões externas ao banco de dados, geralmente é feito alterando a linha <strong>bind-address = 0.0.0.0</strong> em seu arquivo <strong>.conf</strong>
                <br>- Essa porta deve estar acessível para a internet, se o MK-Auto não possuir IP público, deve ser feito no seu firewall
              </div>
              <div v-if="(!analizeResult)" class="row mt-3">
                <div class="col-12 pt-3">
                  <label class="cursor-pointer" for="mkauthIp">
                    IP do MK-Auth
                  </label>
                  <input v-model="analizeForm.host" id="mkauthIp" type="text" class="form-control">
                </div>
                <div class="col-12 pt-3">
                  <label class="cursor-pointer" for="mkauthPort">
                    Porta do MySQL
                  </label>
                  <input v-model.number="analizeForm.port" id="mkauthPort" type="number" class="form-control hide-spin">
                </div>
                <div class="col-12 pt-3">
                  <label class="cursor-pointer" for="mkauthUser">
                    Usuário do MySQL
                  </label>
                  <input v-model="analizeForm.username" id="mkauthUser" type="text" class="form-control">
                </div>
                <div class="col-12 pt-3">
                  <label class="cursor-pointer" for="mkauthPassword">
                    Senha
                  </label>
                  <input v-model="analizeForm.password" id="mkauthPassword" type="password" class="form-control">
                </div>
               <div class="col-12 pt-3">
                  <div class="d-grid gap-2">
                    <button v-on:click="btnAnalizeHandle()" :disabled="!canAnalize()" type="button" class="btn btn-primary">
                      <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
                      Analisar
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="(analizeResult)" class="row mt-3">
                <div class="col-12">
                  <p class="fw-bold mb-0">Importar:</p>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" v-model="importForm.banks" type="checkbox" id="mkauth-import-banks">
                    <label class="form-check-label cursor-pointer" for="mkauth-import-banks">
                      Integrações bancárias ({{ analizeResult.banks }})
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" v-model="importForm.ispPlans" type="checkbox" id="mkauth-import-ispPlans">
                    <label class="form-check-label cursor-pointer" for="mkauth-import-ispPlans">
                      Planos ({{ analizeResult.ispPlans }})
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" v-model="importForm.customers" type="checkbox" id="mkauth-import-customers">
                    <label class="form-check-label cursor-pointer" for="mkauth-import-customers">
                      Clientes ({{ analizeResult.customers }})
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" v-model="importForm.ispSubscriptions" type="checkbox" id="mkauth-import-ispSubscriptions">
                    <label class="form-check-label cursor-pointer" for="mkauth-import-ispSubscriptions">
                      Assinaturas ({{ analizeResult.ispSubscriptions }})
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" v-model="importForm.boletos" type="checkbox" id="mkauth-import-boletos">
                    <label class="form-check-label cursor-pointer" for="mkauth-import-boletos">
                      Boletos ({{ analizeResult.boletos }})
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" v-model="importForm.connectionHistory" type="checkbox" id="mkauth-import-connectionHistory">
                    <label class="form-check-label cursor-pointer" for="mkauth-import-connectionHistory">
                      Histórico de conexões ({{ analizeResult.connectionHistory }})
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input cursor-pointer" v-model="importForm.tickets" type="checkbox" id="mkauth-import-tickets">
                    <label class="form-check-label cursor-pointer" for="mkauth-import-tickets">
                      Chamados ({{ analizeResult.tickets }})
                    </label>
                  </div>
                </div>
                <div class="col-12 pt-3">
                  <div class="d-grid gap-2">
                    <button v-on:click="btnImportHandle()" type="button" class="btn btn-success">
                      <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
                      Importar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-import-from-mkauth-fecha">Fechar</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-import-from-mkauth -->
  </div>
</template>
